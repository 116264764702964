<template>
  <div v-loading="loading" class="custom-dashboard-scheduler" :element-loading-text="loadingText">
    <div class="d-flex p-2" style="border-bottom: 1px solid #ddd">
      <div class="left-card" style="min-width: 10vw">
        <div>
          <i class="fa fa-calendar today-icon"></i>
          <b>{{ component.component_name }}</b>
        </div>
      </div>
      <div class="d-flex right-card top-content">
        <div>
          <el-button type="success" size="mini" @click="approveAllSchedules" :loading="approveLoading"
            v-if="checkApproverUserAccess">Approve & Publish</el-button>
          <!-- <el-button
            type="primary"
            size="mini"
            @click="saveChanges"
            :loading="saveLoading"
            v-if="!isFromApplicationuser"
            >Save</el-button
          > -->
          <el-button type="primary" size="mini" @click="copyFromLastWeek" :loading="copyLoading"
            v-if="!isFromApplicationuser">Copy from last week</el-button>
          <el-button type="primary" size="mini" @click="sendSlotsToApproval" :loading="sendLoading"
            v-if="checkSendApprovalAccess && pendingSlots.length">Send for approval</el-button>
          <el-dropdown trigger="click">
            <el-button icon="el-icon-more" style="transform: rotate(90deg); height: 40px" type="text"></el-button>
            <el-dropdown-menu slot="dropdown">
              <a @click="openDialog">
                <el-dropdown-item>Settings</el-dropdown-item>
              </a>
              <a @click="openConfigDialog" v-if="!hide_options">
                <el-dropdown-item>Edit</el-dropdown-item>
              </a>
              <a @click="deleteComponent" v-if="!hide_options">
                <el-dropdown-item>Delete</el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="d-flex m-2">
      <div class="left-card" style="min-width: 10vw">
        <div>
          <el-date-picker v-model="currentWeek" type="week" format="yyyy MMM" placeholder="Pick a week"
            @change="prepareScheduleEntityData">
          </el-date-picker>
        </div>
      </div>
      <div class="d-flex right-card top-content week-navigation">
        <div>
          <el-button-group>
            <el-button size="mini" type="primary" icon="el-icon-arrow-left" @click="changeWeek(false, false)">Last
              week</el-button>
            <el-button size="mini" type="primary" @click="changeWeek(true)">Current week</el-button>
            <el-button size="mini" type="primary" @click="changeWeek(false, true)">Next week <i
                class="el-icon-arrow-right"></i></el-button>
          </el-button-group>
        </div>
      </div>
    </div>
    <div>
      <div class="calendar">
        <div class="weekView">
          <el-scrollbar>
            <table>
              <thead>
                <tr>
                  <th>
                    <el-select size="mini" placeholder="Please select" v-model="viewBy">
                      <el-option v-for="view in allViews" :key="view.key" :label="view.name"
                        :value="view.key"></el-option>
                    </el-select>
                  </th>
                  <th class="p-1" v-for="day in scheduleEntityData" :key="`${day.weekday} ${day.month}/${day.day}`">
                    <span class="table-header">{{
                      `${day.weekday} ${day.month}/${day.day}`
                    }}</span>
                    <div style="display: flex; gap: 5px">
                      <div>
                        <i class="el-icon-user-solid"></i>
                        <span>{{ getCountValue(day, "RESOURCE") }}</span>
                      </div>

                      <div>
                        <i class="el-icon-time"></i>
                        <span>{{
                          formatTime(getCountValue(day, "HOURS"))
                        }}</span>
                      </div>

                      <div>
                        <i class="el-icon-bank-card"></i>
                        <span>{{ getCountValue(day, "JOB") }}</span>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="selectedView(viewBy)?.filters">
                  <template v-for="filter in selectedView(viewBy).filters">
                    <h3 :key="'resource_' + filter" class="ml-1" style="margin-top: 5px">
                      {{
                        allFiltersData?.[filter.split("@")[1]]?.filter_name ||
                        ""
                      }}
                    </h3>
                    <tr v-for="(row, i) in allDividedEntitiesData[
                      filter.split('@')[1]
                    ]" :key="'resource_' + row._id">
                      <td>
                        {{
                          getLabel(
                            allEntities[selectedView(viewBy)?.entity_id || ""],
                            row,
                            i
                          )
                        }}
                      </td>
                      <td class="table-cell" v-for="day in scheduleEntityData"
                        :key="`${day.weekday}/${day.month}/${day.day}_${row._id}_${row.label}`"
                        :id="`${day.month}_${day.day}_${row._id}_${row.label}`">
                        <template>
                          <span v-if="
                            !employeeAvailabilty[filter.split('@')[1]][
                            row._id
                            ][day.day]
                          ">Unavailable</span>
                          <draggable v-else :list="day.tasks" :group="'tasks'" @end="
                            onEnd(
                              $event,
                              `${day.month}_${day.day}_${row._id}_${row.label}`
                            )
                            " class="dragArea" tag="div" :disabled="checkPreviousTasks(day)">
                            <div v-for="item in (day?.tasks || []).filter(
                              (e) => e[viewBy] == row._id
                            )" :key="item._id" class="job-card" :id="item._id" :style="`padding: 3px 0px 3px; background-color: ${item.color ||
                              jobColors[item[jobView]] ||
                              'lightblue'
                              } !important;`" @click="viewSchedulingData(item, day.day)">
                              {{
                                item[viewBy + "/label"]
                                  ? item[viewBy + "/label"]
                                  : item.label
                              }}
                              <i class="el-icon-circle-close remove-button" @click.stop="deleteSchedulingData(item)"
                                v-if="!isFromApplicationuser && hide_options"></i>
                            </div>
                          </draggable>
                          <div :class="(day?.tasks || []).filter(
                            (e) => e[viewBy] == row._id
                          ).length || checkIsPreviousDate(day)
                            ? 'hidden-button'
                            : ''
                            " v-if="!isFromApplicationuser && checkUserAccess">
                            <div v-if="
                              !(day?.tasks || []).filter(
                                (e) => e[viewBy] == row._id
                              ).length && !checkIsPreviousDate(day)
                            " class="job-card" @click="
                              openDataModal(
                                row,
                                day,
                                (day?.tasks || []).filter(
                                  (e) => e[viewBy] == row._id
                                )
                              )
                              " style="
                                padding: 3px 0px 3px;
                                background-color: #e0b53f;
                              ">
                              Pending
                            </div>
                            <el-link v-else icon="el-icon-plus" :underline="false" type="primary" @click="
                              openDataModal(
                                row,
                                day,
                                (day?.tasks || []).filter(
                                  (e) => e[viewBy] == row._id
                                )
                              )
                              "></el-link>
                          </div>
                        </template>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr v-for="(row, i) in allEntitiesData[
                    selectedView(viewBy)?.entity_id || ''
                  ]" :key="'resource_' + row._id">
                    <td>
                      {{
                        getLabel(
                          allEntities[selectedView(viewBy)?.entity_id || ""],
                          row,
                          i
                        )
                      }}
                    </td>
                    <td class="table-cell" v-for="day in scheduleEntityData"
                      :key="`${day.weekday}/${day.month}/${day.day}_${row._id}_${row.label}`"
                      :id="`${day.month}_${day.day}_${row._id}_${row.label}`">
                      <template>
                        <span v-if="
                          !employeeAvailabilty[
                          selectedView(viewBy)?.entity_id
                          ][row._id][day.day]
                        ">Unavailable</span>
                        <draggable v-else :list="day.tasks" :group="'tasks'" @end="
                          onEnd(
                            $event,
                            `${day.month}_${day.day}_${row._id}_${row.label}`
                          )
                          " class="dragArea" tag="div" :disabled="checkPreviousTasks(day)">
                          <div v-for="item in (day?.tasks || []).filter(
                            (e) => e[viewBy] == row._id
                          )" :key="item._id" class="job-card" :id="item._id" :style="`padding: 3px 0px 3px; background-color: ${item.color ||
                            jobColors[item[jobView]] ||
                            'lightblue'
                            } !important;`" @click="viewSchedulingData(item, day.day)">
                            {{
                              item[viewBy + "/label"]
                                ? item[viewBy + "/label"]
                                : item.label
                            }}
                            <i class="el-icon-circle-close remove-button" @click.stop="deleteSchedulingData(item)"
                              v-if="!isFromApplicationuser && hide_options"></i>
                          </div>
                        </draggable>
                        <div :class="(day?.tasks || []).filter(
                          (e) => e[viewBy] == row._id
                        ).length || checkIsPreviousDate(day)
                          ? 'hidden-button'
                          : ''
                          " v-if="
                            !isFromApplicationuser &&
                            checkUserAccess &&
                            employeeAvailabilty[
                            selectedView(viewBy)?.entity_id
                            ][row._id][day.day]
                          ">
                          <div v-if="
                            !(day?.tasks || []).filter(
                              (e) => e[viewBy] == row._id
                            ).length && !checkIsPreviousDate(day)
                          " class="job-card" @click="
                            openDataModal(
                              row,
                              day,
                              (day?.tasks || []).filter(
                                (e) => e[viewBy] == row._id
                              )
                            )
                            " style="
                              padding: 3px 0px 3px;
                              background-color: #ff7f7f;
                            ">
                            Pending
                          </div>
                          <el-link v-else icon="el-icon-plus" :underline="false" type="primary" @click="
                            openDataModal(
                              row,
                              day,
                              (day?.tasks || []).filter(
                                (e) => e[viewBy] == row._id
                              )
                            )
                            "></el-link>
                        </div>
                      </template>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </el-scrollbar>
        </div>
      </div>
      <el-drawer :title="currentDate" :visible.sync="addingNewData" :with-header="true" size="50%"
        class="scheduler-drawer">
        <div v-if="
          allEntities?.[this.component.scheduler_entity] &&
          addingNewData &&
          !refresh
        ">
          <fields-preview-template :templateData="allEntities[this.component.scheduler_entity].templates[0]
            .templateInfo
            " :has-next="false" :workflow-step-id="allEntities[this.component.scheduler_entity].templates[0]._id
              " :template-data-id="allEntities[this.component.scheduler_entity].templates[0]
                .templateDataId
                " :currentStep="allEntities[this.component.scheduler_entity].templates[0]
                " :entityDataId="entityDataId" :entityData="entityData" :is-execute="isEdit" :layout="'STANDARD'"
            :currentEntity="allEntities?.[this.component.scheduler_entity]" :isEdit="isEdit" :currentMenu="null"
            :entityDataExists="entityDataExists" :suggestionEntityForm="schedulingTemplateForm" :fromScheduling="true"
            v-on:entity-data-created="newDataCreated" v-on:entity-data-added="newDataCreated"
            v-on:onEdit="openInEditMode" v-on:entity-data-updated="reset">
          </fields-preview-template>
        </div>
      </el-drawer>
    </div>
    <dialog-component :title="dialogTitle" :visible="dialogVisible" @before-close="closeDialog"
      :containerMaxWidth="'50vw'">
      <div style="padding: 20px" class="desription-text">
        <div v-if="isFromApplicationuser">
          <el-checkbox v-model="applyRelationship">Apply relationships</el-checkbox>
        </div>
        <div v-else>
          <el-checkbox v-model="updateComponentSettings.allow_previous_slots_replace">Allow previous slots to be
            updated</el-checkbox>
          <el-checkbox v-model="updateComponentSettings.allow_extra_time_job">Allow users to work on extra
            slots</el-checkbox>
        </div>
        <el-divider></el-divider>
        <div v-if="!isFromApplicationuser">
          <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            ">
            <span>Off days</span>
            <el-link icon="el-icon-plus" @click="addOff" :underline="false" type="primary">Add new</el-link>
          </div>
          <div v-for="(offday, i) in updateComponentSettings.offdays" :key="offday.entity_id + '_offday'" class="mt-1"
            style="display: flex; align-items: center; gap: 8px">
            <el-select v-model="offday.entity_id" placeholder="Select entity" size="mini">
              <el-option v-for="entity in getAvailableEntities" :key="entity._id" :label="entity.name"
                :value="entity._id" :disabled="updateComponentSettings.offdays.find(
                  (r, j) => r.entity_id == entity._id && i != j
                )
                  ? true
                  : false
                  "></el-option>
            </el-select>
            <el-select v-model="offday.start_date" placeholder="Select start date" size="mini" clearable>
              <el-option v-for="field in getAllEntityFields(offday.entity_id).filter(
                (e) =>
                  e.inputType == 'DATE' ||
                  (e.input_type == 'FORMULA' && e.result_type == 'DATE')
              )" :key="field.key + '_date_field'" :label="field.label" :value="field.template_key"
                :disabled="field.template_key == offday.end_date"></el-option>
            </el-select>
            <el-select v-model="offday.end_date" placeholder="Select end date" size="mini" clearable>
              <el-option v-for="field in getAllEntityFields(offday.entity_id).filter(
                (e) =>
                  e.inputType == 'DATE' ||
                  (e.input_type == 'FORMULA' && e.result_type == 'DATE')
              )" :key="field.key + '_date_field'" :label="field.label" :value="field.template_key"
                :disabled="field.template_key == offday.start_date"></el-option>
            </el-select>

            <el-select v-model="offday.match_with" placeholder="Match with" size="mini" clearable>
              <el-option v-for="field in getAllEntityFields(offday.entity_id).filter(
                (e) =>
                  e.inputType == 'ENTITY' &&
                  e.entity_id == resource_entity_field?.entity_id
              )" :key="field.key + '_date_field'" :label="field.label" :value="field.template_key"></el-option>
            </el-select>
            <el-button type="text" size="mini" @click="removeOffday(i)">Remove</el-button>
          </div>
        </div>
        <el-divider></el-divider>
        <div v-if="isOwner()">
          <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            ">
            <span>Who can add scheduls?</span>
            <el-link icon="el-icon-plus" @click="addUser" :underline="false" type="primary">Add User</el-link>
          </div>
          <div v-for="(user, i) in updateComponentSettings.users" :key="i + '_approver_offday'" class="mt-1"
            style="display: flex; align-items: center; gap: 8px">
            <el-select v-model="user.user_type" size="mini" clearable placeholder="Select user type">
              <el-option v-for="userType in userTypes.filter(
                (e) =>
                  (isFromApplicationuser && e.value == 'APPLICATION') ||
                  (!isFromApplicationuser && e.value == 'COMPANY')
              )" :key="userType.value" :label="userType.label" :value="userType.value"></el-option>
            </el-select>
            <template v-if="user.user_type == 'COMPANY'">
              <el-select v-model="user.company_user_type" clearable size="mini"
                @change="(value) => updateCompanyUsers(value, user)" placeholder="Select company user type"
                class="ml-1">
                <el-option v-for="item in getUserTypeList" :key="item._id" :label="getUserTypeLabel(item)"
                  :value="item._id"></el-option>
              </el-select>
              <el-select v-if="user.company_user_type" v-model="user.preferred_company_users" clearable size="mini"
                multiple placeholder="Select preferred user" class="ml-1" default-first-option>
                <el-option v-for="item in getCompanyUsersByType(user.company_user_type)"
                  :key="item._id + '_company_user'" :label="item.email" :value="item._id">{{ item.email }}</el-option>
              </el-select>
            </template>
            <template v-else-if="user.user_type == 'APPLICATION'">
              <el-select v-model="user.application_user_type" clearable size="mini"
                @change="updateApplicationUserType(user)" placeholder="Select application user type" class="ml-1">
                <el-option v-for="(type, index) in getAllContactTypes?.data ||
                  getAllContactTypes" :key="index + 1" :value="type._id" :label="type.name">{{ type.name }}</el-option>
              </el-select>
              <div class="ml-1" v-if="user.application_user_type">
                <el-select v-model="user.preferred_application_users" clearable multiple size="mini"
                  placeholder="Select preferred user" :disabled="user.relational_user">
                  <el-option v-for="(contact, index) in getUserTypeContacts(
                    user.application_user_type
                  )" :key="index + '_application_user_mail'" :value="contact.email" :label="contact.email">{{
                    contact.email }}</el-option>
                </el-select>
              </div>
            </template>
            <el-button type="text" size="mini" @click="removeUser(i)">Remove</el-button>
          </div>
        </div>
        <el-divider v-if="isOwner()"></el-divider>
        <div v-if="isOwner()">
          <div style="
              display: flex;
              justify-content: space-between;
              align-items: center;
            ">
            <span>Approvers</span>
            <el-link icon="el-icon-plus" @click="addApprover" :underline="false" type="primary">Add approver</el-link>
          </div>
          <div v-for="(user, i) in updateComponentSettings.approvers" :key="i + '_approver_offday'" class="mt-1"
            style="display: flex; align-items: center; gap: 8px">
            <el-select v-model="user.user_type" size="mini" clearable placeholder="Select user type">
              <el-option v-for="userType in userTypes.filter(
                (e) =>
                  (isFromApplicationuser && e.value == 'APPLICATION') ||
                  (!isFromApplicationuser && e.value == 'COMPANY')
              )" :key="userType.value" :label="userType.label" :value="userType.value"></el-option>
            </el-select>
            <template v-if="user.user_type == 'COMPANY'">
              <el-select v-model="user.company_user_type" clearable size="mini"
                @change="(value) => updateCompanyUsers(value, user)" placeholder="Select company user type"
                class="ml-1">
                <el-option v-for="item in getUserTypeList" :key="item._id" :label="getUserTypeLabel(item)"
                  :value="item._id"></el-option>
              </el-select>
              <el-select v-if="user.company_user_type" v-model="user.preferred_company_users" clearable size="mini"
                multiple placeholder="Select preferred user" class="ml-1" default-first-option>
                <el-option v-for="item in getCompanyUsersByType(user.company_user_type)"
                  :key="item._id + '_company_user'" :label="item.email" :value="item._id">{{ item.email }}</el-option>
              </el-select>
            </template>
            <template v-else-if="user.user_type == 'APPLICATION'">
              <el-select v-model="user.application_user_type" clearable size="mini"
                @change="updateApplicationUserType(user)" placeholder="Select application user type" class="ml-1">
                <el-option v-for="(type, index) in getAllContactTypes?.data ||
                  getAllContactTypes" :key="index + 1" :value="type._id" :label="type.name">{{ type.name }}</el-option>
              </el-select>
              <div class="ml-1" v-if="user.application_user_type">
                <el-select v-model="user.preferred_application_users" clearable multiple size="mini"
                  placeholder="Select preferred user" :disabled="user.relational_user">
                  <el-option v-for="(contact, index) in getUserTypeContacts(
                    user.application_user_type
                  )" :key="index + '_application_user_mail'" :value="contact.email" :label="contact.email">{{
                    contact.email }}</el-option>
                </el-select>
              </div>
            </template>
            <el-button type="text" size="mini" @click="removeApprover(i)">Remove</el-button>
          </div>
        </div>
        <el-divider v-if="isOwner()"></el-divider>
      </div>
      <span slot="footer">
        <button @click="closeDialog"><i class="fa fa-close"></i> Cancel</button>
        <button type="primary" @click="saveSettings">
          <i class="fa fa-check-square-o"></i> Save
        </button>
      </span>
    </dialog-component>
    <dialog-component :width="getIsMobile ? '100%' : '30%'" :visible="openDialogVisible" :title="'Edit Component'"
      @before-close="openDialogVisible = false" :close-on-click-modal="false" :close-on-press-escape="false">
      <div v-loading="loading">
        <CustomComponentEdit :addStatsData="updateComponentData" :getRelationshipEntities="getRelationshipEntities"
          :selectedEntity="allEntities?.[this.component.scheduler_entity]" :globalVariablesData="globalVariablesData"
          :permittedEntities="permittedEntities" :getAllEntities="getAllEntities"
          :getAllCompanyTemplatesData="{ data: getAllCompanyTemplatesData }"></CustomComponentEdit>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="btn btn-outline-danger btn-sm m-lr-1" type="button" @click="openDialogVisible = false">
          <i class="fa fa-close"></i> Cancel
        </button>
        <button class="btn btn-outline-primary btn-sm m-lr-1" type="primary" @click="updateComponent">
          <i class="fa fa-sign-out"></i> Save
        </button>
      </span>
    </dialog-component>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import moment from "moment";
import { postAPICall } from "@/helpers/httpHelper";
import CustomDashboardHelper from "@/mixins/CustomDashboardHelper.js";
import { fetchFilterById } from "@/repo/filtersRepo";
import entityRelationshipMixin from "../../mixins/entityRelationshipMixin";
import EntitiesHelper from "../../mixins/EntitiesHelper";
import permissionsHelper from "../../mixins/permissionsHelper";
import templateBuilderHelper from "../../mixins/templateBuilderHelper";

export default {
  data() {
    return {
      loading: false,
      loadingText: "Getting info..",
      shouldCenterMonth: false,
      currentDate: "",
      currentYear: new Date().getFullYear(),
      componentData: null,
      currentView: "Month",
      daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      hours: [
        "12AM",
        "1AM",
        "2AM",
        "3AM",
        "4AM",
        "5AM",
        "6AM",
        "7AM",
        "8AM",
        "9AM",
        "10AM",
        "11AM",
        "12PM",
        "1PM",
        "2PM",
        "3PM",
        "4PM",
        "5PM",
        "6PM",
        "7PM",
        "8PM",
        "9PM",
        "10PM",
        "11PM",
      ],
      monthsNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      openNewEventModal: false,
      openNewEventLoading: false,
      selectedEntity: "",
      currentEntity: null,
      entityAllFields: [],
      form: {},
      primaryFields: [],
      selectedData: null,
      showChildEntityDataDialog: false,
      calenderDataByMonth: {},
      calenderDateRangeByMonth: {},
      currentWeek: new Date(),
      allEntitiesData: {},
      allEntities: {},
      viewBy: "",
      allViews: [],
      addingNewData: false,
      schedulingTemplateForm: {},
      resource_entity_field: "",
      job_entity_field: "",
      scheduler_entity_sub_entity_field: {},
      entityFieldsPreferredData: {},
      scheduleEntityData: [],
      scheduleEntityDataObject: {},
      refresh: false,
      jobColors: {},
      saveLoading: false,
      approveLoading: false,
      copyLoading: false,
      newlyAddedData: [],
      dialogVisible: false,
      dialogTitle: "",
      updateComponentSettings: {
        allow_previous_slots_replace: true,
        allow_extra_time_job: false,
        offdays: [],
        approvers: [],
        users: [],
      },
      applyRelationship: false,
      allDividedEntitiesData: {},
      allFiltersData: {},
      entityData: null,
      isEdit: false,
      entityDataExists: false,
      entityDataId: null,
      existedDates: {},
      relationshipsData: [],
      userTypes: [
        { value: "APPLICATION", label: "Application Users" },
        { value: "COMPANY", label: "Company Users" },
        // { value: "LINKEDFIELD", label: "Based on filled data" },
      ],
      company_users: [],
      allContacts: [],
      openDialogVisible: false,
      updateComponentData: null,
      employeeAvailabilty: {},
      sendLoading: false,
      pendingSlots: [],
      colorRulesFields: [],
    };
  },
  components: {
    draggable,
    FieldsPreviewTemplate: () =>
      import("@/components/templates/templateFieldsPreview.vue"),
    CustomComponentEdit: () =>
      import("@/components/customDashboard/customComponentEdit.vue"),
  },
  props: [
    "isFromApplicationuser",
    "selectedEntities",
    "customDashboard",
    "customDashboardData",
    "parentDate",
    "parentView",
    "component",
    "index",
    "hide_options",
    "getAllCompanyTemplatesData",
    "getAllEntities",
    "getRelationshipEntities",
    "globalVariablesData",
    "permittedEntities",
  ],
  mixins: [
    CustomDashboardHelper,
    entityRelationshipMixin,
    EntitiesHelper,
    permissionsHelper,
    templateBuilderHelper,
  ],
  computed: {
    ...mapGetters("entities", ["getEntityDataById"]),
    ...mapGetters("templatesData", [
      "getNewEntityData",
      "getPrimaryEntityDataStatus",
      "getTemplatesData",
      "getTemplatesPrimaryData",
      "getformtemplateinvoiceinfo",
      "getDuplicateDataCheck",
    ]),
    ...mapGetters("auth", [
      "getDefaultDateFormat",
      "getUserTypeList",
      "getCompanyUsers",
      "getActiveContactType",
    ]),
    ...mapGetters("contactTypes", ["getAllContactTypes"]),
    ...mapGetters("contacts", ["getAllContacts"]),
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    getAllViews() {
      let result = [];
      if (
        this.resource_entity_field?.entity_id &&
        this.allEntities?.[this.resource_entity_field.entity_id] &&
        !this.component.applyRelationship
      ) {
        result.push(this.getView(this.resource_entity_field));
      }
      if (
        this.job_entity_field?.entity_id &&
        this.allEntities?.[this.job_entity_field.entity_id]
      ) {
        result.push(this.getView(this.job_entity_field));
      }
      if (
        this.scheduler_entity_sub_entity_field &&
        this.component?.scheduler_entity_sub_groups
      ) {
        (this.component.scheduler_entity_sub_groups || []).map((e) => {
          let field = this.scheduler_entity_sub_entity_field[e];
          if (field?.entity_id && this.allEntities?.[field.entity_id]) {
            let dividingFilters = (
              this.component?.scheduler_entity_sub_groups_filters || []
            ).filter((fl) => fl.split("@")[0] == e);
            result.push(this.getView(field, dividingFilters));
          }
        });
      }
      return result;
    },
    jobView() {
      return (
        this.allViews.find(
          (e) => e.entity_id == this.job_entity_field?.entity_id
        )?.key || ""
      );
    },
    getAvailableEntities() {
      return (this.getAllEntities?.data || []).filter((entity) => {
        return this.allEntities[entity._id] ? false : true;
      });
    },
    getUserTypeLabel() {
      return (userType) => {
        if (userType && userType.role) {
          return userType.role.title + " - " + userType.name;
        } else {
          return userType.name;
        }
      };
    },
    checkSendApprovalAccess() {
      if (this.component?.settings?.users) {
        return this.component.settings.users.find((user) =>
          this.checkUser(user)
        )
          ? true
          : false;
      }
      return false;
    },
    checkUserAccess() {
      if (this.component?.settings?.users) {
        return this.component.settings.users.find((user) =>
          this.checkUser(user)
        )
          ? true
          : false;
      }
      return true;
    },
    checkApproverUserAccess() {
      if (this.component?.settings?.approvers) {
        return this.component.settings.approvers.find((user) =>
          this.checkUser(user)
        )
          ? true
          : false;
      }
      return false;
    },
  },
  mounted() {
    this.componentData = JSON.parse(JSON.stringify(this.component));
    this.getNeccessaryDetails();
  },
  methods: {
    openInEditMode() {
      this.refresh = true;
      this.isEdit = true;
      setTimeout(() => {
        this.refresh = false;
      }, 500);
    },
    checkUser(user) {
      if (user?.user_type == "COMPANY") {
        if (
          user?.company_user_type ==
          this.getAuthenticatedUser?.activeRole?.userType_id
        ) {
          return (
            !user.preferred_company_users ||
            !user.preferred_company_users.length ||
            user.preferred_company_users.includes(
              this.getAuthenticatedUser?._id
            )
          );
        }
      } else if (user?.user_type == "APPLICATION") {
        if (!this.isFromApplicationuser) {
          return false;
        } else if (
          this.getActiveContactType?.contact_type?._id ==
          user.application_user_type
        ) {
          return (
            !user.preferred_company_users ||
            !user.preferred_company_users.length ||
            user.preferred_application_users.includes(
              this.getAuthenticatedUser?.email
            )
          );
        }
      }
      return false;
    },
    schedulerWeekDays() {
      const startDate = new Date(
        this.currentWeek.getFullYear(),
        this.currentWeek.getMonth(),
        this.currentWeek.getDate() - this.currentWeek.getDay()
      );
      const weekDays = [];

      for (let i = 0; i < 7; i++) {
        const date = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + i
        );
        weekDays.push({
          day: date.getDate(),
          month: date.getMonth() + 1,
          weekday: this.daysOfWeek[date.getDay()],
          year: date.getFullYear(),
          tasks: [],
        });
      }
      return weekDays;
    },
    async fetchContacts() {
      try {
        await this.$store.dispatch("contacts/fetchAllContacts", {
          get_all: true,
          type: "CONTACT",
        });
        this.allContacts = this.getAllContacts.data;
      } catch (err) {
        this.loading = false;
      }
    },
    async getCompanyUsersList() {
      try {
        await Promise.all([
          this.$store.dispatch(
            "auth/getCompanyUsersList",
            this.getAuthenticatedUser?.current_workspace?.company_id
          ),
          this.$store.dispatch("auth/getUserTypeList", {
            companyId: this.getAuthenticatedUser?.current_workspace?.company_id,
            isMenu: true,
          }),
        ]);
        if (this.getCompanyUsers?.data) {
          this.company_users = this.getCompanyUsers.data;
          if (
            !this.company_users
              .flatMap((e) => e._id)
              .includes(this.getAuthenticatedUser._id)
          ) {
            this.company_users.push(this.getAuthenticatedUser);
          }
        }
      } catch (err) {
        console.log("Error:", err);
      }
    },
    getUserTypeContacts(type) {
      return this.allContacts.filter((e) => {
        if (e?.contact_types) {
          return e.contact_types.find(
            (el) => el?.contact_type?._id == type || el?.contact_type == type
          )
            ? true
            : false;
        }
      });
    },



    getCompanyUsersByType(type) {
      return this.company_users.filter((e) => {
        if (e?.rolesList) {
          return e.rolesList.find((el) => el?.userType?._id == type)
            ? true
            : false;
        } else {
          return e.roles.find((el) => el?.userType_id == type) ? true : false;
        }
      });
    },

    updateApplicationUserType(user) {
      this.$set(user, "preferred_application_users", []);
    },

    updateCompanyUsers(value, user) {
      this.$set(user, "preferred_company_users", []);
      if (value) {
        let users = this.getCompanyUsersByType(value);
        if (users && users.length == 1) {
          user.preferred_company_users = [...users.map((e) => e._id)];
        }
      }
    },
    getAllEntityFields(entity_id) {
      let allFields = [];
      if (entity_id) {
        let selectedEntity = (this.getAllEntities?.data || []).find(
          (e) => e._id == entity_id
        );
        (selectedEntity.templates || []).forEach((temp) => {
          let selectedTemplate = this.getAllCompanyTemplatesData.find(
            (e) => e._id == temp?.template_id
          );
          if (selectedTemplate?.sections?.[0]?.fields) {
            allFields = [
              ...allFields,
              ...selectedTemplate.sections[0].fields.map((field) => {
                if (field.key && field.key.includes("#")) {
                  field.key = field.key.split("#")[0];
                }
                field.template_id = temp.template_id;
                field.template_key = temp.template_id + "#" + field.key;
                return field;
              }),
            ];
          }
        });
      }
      return allFields;
    },
    addOff() {
      this.updateComponentSettings.offdays.push({
        entity_id: "",
        match_with: "",
        filter_id: "",
        start_date: "",
        end_date: "",
      });
    },
    removeOffday(index) {
      this.updateComponentSettings.offdays.splice(index, 1);
    },
    addUser() {
      this.updateComponentSettings.users.push({
        entity_id: "",
        match_with: "",
        filter_id: "",
        start_date: "",
        end_date: "",
      });
    },
    removeUser(index) {
      this.updateComponentSettings.users.splice(index, 1);
    },
    addApprover() {
      this.updateComponentSettings.approvers.push({
        entity_id: "",
        match_with: "",
        filter_id: "",
        start_date: "",
        end_date: "",
      });
    },
    removeApprover(index) {
      this.updateComponentSettings.approvers.splice(index, 1);
    },
    async viewSchedulingData(item, day) {
      this.currentDate = moment(
        new Date(
          this.currentWeek.getFullYear(),
          this.currentWeek.getMonth(),
          day
        )
      ).format(this.getDefaultDateFormat);
      const response = await postAPICall("GET", `/entity-data/${item._id}`, {
        id: item._id,
      });
      this.entityData = { ...response.data };
      this.isEdit = false;
      this.entityDataExists = true;
      this.entityDataId = item._id;
      this.schedulingTemplateForm = null;
      this.addingNewData = true;
    },
    async deleteSchedulingData(item) {
      this.$confirm("Are you sure want to delete this ?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(async () => {
        this.loading = true;
        this.loadingText = "Deleting...";
        await postAPICall("POST", "/templates-data/delete-schedules", {
          entity_data_ids: [item._id],
        });
        this.loadingText = "Getting info...";
        await this.resetData();
        this.loading = false;
      });
    },
    formatTime(totalHours) {
      if (isNaN(totalHours)) return "00:00";

      const hours = Math.floor(totalHours);

      const minutes = Math.floor((totalHours - hours) * 60);

      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");

      return `${formattedHours}:${formattedMinutes}`;
    },
    getCountValue(day, type) {
      if (type == "JOB") {
        return day?.tasks?.length || 0;
      } else if (type == "RESOURCE") {
        let eCount = 0,
          emp = {};
        (day.tasks || []).forEach((t) => {
          if (
            !emp[
            t[
            this.resource_entity_field.template_id +
            "#" +
            this.resource_entity_field.key
            ]
            ]
          ) {
            eCount++;
            emp[
              t[
              this.resource_entity_field.template_id +
              "#" +
              this.resource_entity_field.key
              ]
            ] = true;
          }
        });
        return eCount;
      } else if (type == "HOURS") {
        let hCount = 0;
        (day.tasks || []).forEach((t) => {
          if (
            t &&
            t[this.component.job_entity] &&
            this.job_entity_field?.entity_id &&
            this.component?.job_entity_time_field
          ) {
            let job = (
              this.allEntitiesData[this.job_entity_field.entity_id] || []
            ).find((e) => e._id == t[this.component.job_entity]);
            let [temp, k] = this.component.job_entity_time_field.split("#");
            if (temp && k && job && job.entityData?.[temp]?.[k]) {
              hCount += job.entityData[temp][k];
            }
          }
        });
        return hCount;
      }
      return 0;
    },
    calculateTotalHours(dataIds, entity_id, field) {
      let tasks = (this.allEntitiesData?.[entity_id] || []).filter((e) =>
        dataIds.includes(e._id)
      );
      let [t, k] = field.split("#");
      return tasks.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue?.entityData?.[t]?.[k] || 0),
        0
      );
    },
    checkPreviousTasks(obj) {
      if (this.isApplicationUser) {
        return true;
      }
      let year = new Date(this.currentWeek).getFullYear();
      let { day, month } = obj;
      let slotData = new Date(year, month - 1, day);
      if (
        slotData.getTime() <= new Date().getTime() &&
        this.component?.settings?.allow_previous_slots_replace
      ) {
        return true;
      }
      return false;
    },
    openDialog() {
      if (this.component?.settings) {
        this.updateComponentSettings = { ...this.component.settings };
      }
      this.applyRelationship = this.component.applyRelationship;
      this.dialogTitle = "Settings";
      this.dialogVisible = true;
    },
    closeDialog() {
      this.updateComponentSettings = {
        allow_previous_slots_replace: true,
        allow_extra_time_job: false,
        offdays: [],
        approvers: [],
        users: [],
      };
      this.applyRelationship = false;
      this.dialogTitle = "";
      this.dialogVisible = false;
    },
    saveSettings() {
      this.component["settings"] = { ...this.updateComponentSettings };
      this.component["applyRelationship"] = this.applyRelationship;
      this.dialogTitle = "";
      this.dialogVisible = false;
    },
    async approveAllSchedules() {
      let approvalData = this.allEntitiesData[
        this.component.scheduler_entity
      ].filter(
        (data) => data.status == "ARCHIVED" && data.is_created_from_scheduling
      );
      let approvalIds = approvalData.map((e) => e._id);
      if (!approvalIds.length) {
        this.$message({
          message: "There is no slots to approve",
          type: "warning",
        });
        return;
      }
      let entity = this.allEntities[this.component.scheduler_entity];
      await this.fetchEntityRelationships(entity);
      await Promise.all([
        ...approvalData.map(async (aData) => {
          await Promise.all(
            entity.templates.map((temp) => {
              this.updateRelationshipsData(
                {
                  entity_data_id: aData._id,
                  template_data: aData?.entityData?.[temp.template_id],
                },
                temp.templateInfo,
                this.relationshipsData
              );
            })
          );
        }),
        this.approveSchedules(approvalIds),
      ]);
    },
    async approveSchedules(approvalIds) {
      this.approveLoading = true;
      await postAPICall("POST", "/templates-data/approve-schedules", {
        entity_data_ids: approvalIds,
      });
      this.$message({
        message: "Approved successfully",
        type: "success",
      });
      this.approveLoading = false;
    },
    async saveChanges() {
      let params = this.buildSlotsData(
        this.allEntitiesData[this.component.scheduler_entity]
      );
      if (params.length) {
        this.saveLoading = true;
        await postAPICall("POST", "/templates-data/bulk-update", params);
        this.$message({
          message:
            (this.allEntities?.[this.scheduler_entity]?.name || "Slot") +
            "updated successfully",
          type: "success",
        });
        this.saveLoading = false;
      } else {
        this.$message({
          message:
            "You haven't changed any " +
            (this.allEntities?.[this.scheduler_entity]?.name || " slot"),
          type: "warning",
        });
      }
    },
    buildSlotsData(data) {
      let result = [];
      let mainTemplates = [
        this.component.scheduler_entity_date_field.split("#")[0],
        ...this.allViews.flatMap((e) => e.key.split("#")[0]),
      ];
      data.forEach((dt) => {
        if (dt.changed) {
          if (dt?.entityData) {
            for (const [key, value] of Object.entries(dt.entityData)) {
              if (mainTemplates.includes(key)) {
                result.push({
                  entity_data_id: dt._id,
                  template_id: key,
                  template_data: value,
                  update_status: true,
                  update_data: true,
                });
              }
            }
          }
        }
      });
      result = [
        ...result,
        ...this.newlyAddedData.map((e) => {
          return {
            entity_data_id: e,
            update_status: true,
            update_data: false,
          };
        }),
      ];
      return result;
    },
    getLastweekDates() {
      let currentDate = new Date(this.currentWeek);
      let startOfWeek = new Date(
        currentDate.setDate(currentDate.getDate() - currentDate.getDay())
      );
      let lastWeekStartDate = new Date(startOfWeek);
      lastWeekStartDate.setDate(lastWeekStartDate.getDate() - 7);

      let lastWeekEndDate = new Date(lastWeekStartDate);
      lastWeekEndDate.setDate(lastWeekEndDate.getDate() + 6);
      return [lastWeekStartDate, lastWeekEndDate];
    },
    getBetweenDatesSlots(data, start, end) {
      let [dateTemplate, dateField] =
        this.component.scheduler_entity_date_field.split("#");
      return data.filter((dt) => {
        if (dt?.entityData?.[dateTemplate]?.[dateField]) {
          let slotDate = new Date(dt?.entityData?.[dateTemplate]?.[dateField]);
          return slotDate >= new Date(start) && slotDate <= new Date(end);
        }
        return false;
      });
    },
    getNextWeekDate(dateStr) {
      let date = new Date(dateStr);
      date.setDate(date.getDate() + 7);
      return date.toISOString();
    },
    buildCopyScedules(data) {
      let [dateTemplate, dateField] =
        this.component.scheduler_entity_date_field.split("#");
      return data.map((d) => {
        let dt = JSON.parse(JSON.stringify(d));
        let existed_data = dt?.entityData;
        if (existed_data?.[dateTemplate]?.[dateField]) {
          existed_data[dateTemplate][dateField] = this.getNextWeekDate(
            existed_data[dateTemplate][dateField]
          );
        }
        return {
          existed_data_id: dt._id,
          data: existed_data,
          hard_duplicate: true,
          status: "ARCHIVED",
          is_created_from_scheduling: true,
        };
      });
    },
    getWeekStartAndEnd(date) {
      const givenDate = new Date(date);
      const dayOfWeek = givenDate.getDay();
      const startOfWeek = new Date(givenDate);
      startOfWeek.setDate(givenDate.getDate() - dayOfWeek);
      const endOfWeek = new Date(givenDate);
      endOfWeek.setDate(givenDate.getDate() + (6 - dayOfWeek));
      return [startOfWeek, endOfWeek];
    },
    async sendSlotsToApproval() {
      this.sendLoading = true;
      await postAPICall("POST", "/entities-data/sent", {
        entity_data_ids: this.pendingSlots.map((e) => e._id),
      });
      this.sendLoading = false;
      this.$message({
        message: "Sent successfully",
        type: "success",
      });
      await this.resetData();
    },
    async copyFromLastWeek() {
      let [lastWeekStartDate, lastWeekEndDate] = this.getLastweekDates();
      let slots = this.getBetweenDatesSlots(
        this.allEntitiesData[this.component.scheduler_entity],
        lastWeekStartDate,
        lastWeekEndDate
      );
      if (slots.length) {
        let duplicate_data = this.buildCopyScedules(slots);
        let params = {
          entity_id: this.component.scheduler_entity,
          duplicate_data: duplicate_data,
          current_date: new Date().toISOString(),
        };
        if (this.component?.settings?.offdays) {
          params["offdays"] = this.component.settings.offdays;
          params["approvers"] = this.component.settings.approvers;
          params["users"] = this.component.settings.users;
          let [st, ed] = this.getWeekStartAndEnd(this.currentWeek);
          params["start"] = {
            start: this.getDateOffset(moment(st), "start"),
            end: this.getDateOffset(moment(st), "end"),
          };
          params["end"] = {
            start: this.getDateOffset(moment(ed), "start"),
            end: this.getDateOffset(moment(ed), "end"),
          };
          params["resource_entity"] = this.component.resource_entity;
          params["scheduler_entity_date_field"] =
            this.component.scheduler_entity_date_field;
        }
        this.copyLoading = true;
        await postAPICall("POST", "/entities-data/duplicate/multiple", params);
        this.$message({
          message:
            (this.allEntities?.[this.scheduler_entity]?.name || "Slot") +
            "copied from last week successfully",
          type: "success",
        });
        if (this.job_entity_field?.entity_id) {
          await this.resetData();
        }
        this.copyLoading = false;
      } else {
        this.$message({
          message:
            "There is no " +
            (this.allEntities?.[this.scheduler_entity]?.name || " slot") +
            " have saved on last week",
          type: "warning",
        });
      }
    },
    selectedView(viewBy) {
      if (viewBy) {
        return this.allViews.find((f) => f.key == viewBy);
      }
      return "";
    },
    generateRandomLightColor() {
      const generateRandomComponent = () => {
        return Math.floor(Math.random() * 102) + 153;
      };

      const r = generateRandomComponent().toString(16).padStart(2, "0");
      const g = generateRandomComponent().toString(16).padStart(2, "0");
      const b = generateRandomComponent().toString(16).padStart(2, "0");

      return `#${r}${g}${b}`;
    },
    onEnd(event, presentId) {
      if (event?.to?.offsetParent?.id) {
        let selectedId = event?.item?.id || "";
        let [month, date, exId, exlabel] = event.to.offsetParent.id.split("_");
        let [pMonth, pDate] = presentId.split("_");
        if (
          presentId !== event.to.offsetParent.id &&
          this.scheduleEntityDataObject?.[pDate + "-" + pMonth]?.[selectedId]
        ) {
          let selectedItem =
            this.scheduleEntityDataObject[pDate + "-" + pMonth][selectedId];

          if (selectedItem) {
            selectedItem["day"] = date;
            selectedItem[this.viewBy] = exId;
            selectedItem["month"] = month;
          }
          // let newIndex = this.scheduleEntityData.findIndex(
          //   (e) => e.day == date && e.month == month
          // );
          let existedIndex = this.scheduleEntityData.findIndex(
            (e) => e.day == pDate && e.month == pMonth
          );
          if (this.scheduleEntityData[existedIndex]?.tasks) {
            this.scheduleEntityData[existedIndex]?.tasks.filter((e) => {
              e._id !== selectedItem._id;
            });
          }
          // if (this.scheduleEntityData[newIndex]?.tasks) {
          //   let duplicate = this.scheduleEntityData[newIndex].tasks.find(
          //     (e) => e._id == selectedItem._id
          //   );
          //   if (!duplicate) {
          //     this.scheduleEntityData[newIndex].tasks.push(selectedItem);
          //   }
          // } else {
          //   this.scheduleEntityData[newIndex]["tasks"] = [selectedItem];
          // }
          let rowObject = JSON.parse(
            JSON.stringify(this.scheduleEntityDataObject[pDate + "-" + pMonth])
          );
          delete rowObject[selectedId];
          this.$set(
            this.scheduleEntityDataObject,
            pDate + "-" + pMonth,
            rowObject
          );
          this.scheduleEntityDataObject[date + "-" + month] = {
            ...this.scheduleEntityDataObject[date + "-" + month],
            ...{
              ...{
                [selectedId]: selectedItem,
              },
            },
          };
          this.allEntitiesData[this.component.scheduler_entity] =
            this.allEntitiesData[this.component.scheduler_entity].map((el) => {
              if (el?._id === selectedItem._id) {
                el.changed = true;

                let [dateTemplate, dateField] =
                  this.component.scheduler_entity_date_field.split("#");
                if (el?.entityData?.[dateTemplate]?.[dateField]) {
                  el.entityData[dateTemplate][dateField] = moment(
                    new Date(el.entityData[dateTemplate][dateField])
                  )
                    .month(month - 1)
                    .date(date)
                    .toISOString();
                }

                let [jobTemplate, jobField] = this.viewBy.split("#");
                if (
                  jobTemplate &&
                  jobField &&
                  el?.entityData?.[dateTemplate]?.[jobField]
                ) {
                  el.entityData[jobTemplate][jobField] = exId;
                  el.entityData[jobTemplate][jobField + "/name"] = exlabel;
                }
              }
              return el;
            });
        }
      }
    },
    checkSlotAccess(slot) {
      if (
        slot?.status == "ACTIVE" ||
        !this.component?.settings?.users?.length ||
        (this.checkUserAccess &&
          slot.user_id == this.getAuthenticatedUser?._id) ||
        (this.checkApproverUserAccess && slot.sent_for_approval)
      ) {
        return true;
      }
      return false;
    },
    async prepareScheduleEntityData() {
      this.loadingText = "Fetching data...";
      this.loading = true;
      this.scheduleEntityData = [];
      this.pendingSlots = [];
      let weeks = [...this.schedulerWeekDays()];
      let [dateTemplate, dateField] =
        this.component.scheduler_entity_date_field.split("#");
      if (this.allEntitiesData[this.component.scheduler_entity]) {
        (this.allEntitiesData[this.component.scheduler_entity] || []).forEach(
          (data, i) => {
            if (data?.entityData?.[dateTemplate]?.[dateField]) {
              if (this.checkSlotAccess(data)) {
                if (data?.status == "ARCHIVED" && !data.sent_for_approval) {
                  this.pendingSlots.push(data);
                }
                let [day, month, year] = moment(
                  new Date(data.entityData[dateTemplate][dateField])
                )
                  .format("D-M-Y")
                  .split("-");
                let obj = {
                  _id: data._id,
                  label: this.getLabel(
                    this.allEntities[data.entity_id],
                    data,
                    i
                  ),
                  day: day,
                  month: month,
                  year: year,
                };
                if (this.component?.scheduler_entity_resource_label_field) {
                  obj[this.component.resource_entity + "/label"] =
                    this.getLabelFromFields(
                      this.component.scheduler_entity_resource_label_field,
                      data
                    );
                }
                if (this.component?.scheduler_entity_job_label_date_field) {
                  obj[this.component.job_entity + "/label"] =
                    this.getLabelFromFields(
                      this.component.scheduler_entity_job_label_date_field,
                      data
                    );
                }
                (this.allViews || []).forEach((view) => {
                  obj[view.key] = this.getViewByData(
                    data,
                    this.selectedView(view.key)
                  );
                });
                let dayIndex = weeks.findIndex(
                  (wk) => wk.day == day && wk.month == month
                );
                if (this.component.rules) {
                  let rowData = {};
                  Object.entries(data.entityData).forEach(
                    ([tempId, values]) => {
                      Object.entries(values).forEach(([key, value]) => {
                        rowData[`${tempId}#${key}`] = value;
                      });
                    }
                  );
                  let results = this.component.rules.map((rule) =>
                    this.checkSingleRule(
                      rule,
                      rowData,
                      this.colorRulesFields,
                      true
                    )
                  );
                  if (results.some((r) => r)) {
                    let appliedRuleIndex = results.findIndex((r) => r);
                    obj.color =
                      this.component.rules[appliedRuleIndex].actions[0].color;
                  }
                }
                if (dayIndex !== -1) {
                  if (weeks[dayIndex].tasks) {
                    let duplicate = weeks[dayIndex].tasks.find(
                      (e) => e._id == data._id
                    );
                    if (!duplicate) {
                      weeks[dayIndex].tasks.push(obj);
                    }
                  } else {
                    weeks[dayIndex].tasks = [obj];
                  }
                }
                this.scheduleEntityDataObject[day + "-" + month] = {
                  ...this.scheduleEntityDataObject[day + "-" + month],
                  ...{
                    [data._id]: obj,
                  },
                };
              }
            }
          }
        );
      }
      this.scheduleEntityData = [...weeks];
      this.employeeAvailabilty = {};
      await Promise.all([
        ...Object.keys(this.allDividedEntitiesData || {}).map(
          async (filter_id) => {
            const filterAvailability = {};
            const filterRows = this.allDividedEntitiesData[filter_id];
            await Promise.all(
              filterRows.map(async (row) => {
                const availability = {};
                await Promise.all(
                  weeks.map(async (day) => {
                    availability[day.day] =
                      await this.checkEmployeeAvailability(row._id, day.day);
                  })
                );
                filterAvailability[row._id] = availability;
              })
            );
            this.employeeAvailabilty[filter_id] = filterAvailability;
          }
        ),
        ...Object.keys(this.allEntitiesData || {}).map(async (entity_id) => {
          const entityAvailability = {};
          const entityRows = this.allEntitiesData[entity_id];
          await Promise.all(
            entityRows.map(async (row) => {
              const availability = {};
              await Promise.all(
                weeks.map(async (day) => {
                  availability[day.day] = await this.checkEmployeeAvailability(
                    row._id,
                    day.day
                  );
                })
              );
              entityAvailability[row._id] = availability;
            })
          );
          this.employeeAvailabilty[entity_id] = entityAvailability;
        }),
      ]);
      this.loading = false;
    },
    getViewByData(data, selectedView) {
      if (selectedView?.key) {
        let [viewByTemplate, viewByField] = selectedView.key.split("#");
        return data?.entityData?.[viewByTemplate]?.[viewByField] || "";
      }
      return null;
    },
    async resetData() {
      await this.fetchEntityData(this.component.scheduler_entity);
      this.allViews = this.getAllViews;
      this.setColours();
      await this.prepareScheduleEntityData();
    },
    async newDataCreated(data) {
      if (data?.dataId) {
        this.newlyAddedData.push(data.dataId);
      }
      this.reset();
    },
    reset() {
      this.resetData();
      this.addingNewData = false;
    },
    setEntityIds() {
      if (this.component?.resource_entity) {
        let [template_id, field_key] =
          this.component.resource_entity.split("#");
        let selectedTemplate = this.getAllCompanyTemplatesData.find(
          (e) => e._id == template_id
        );
        this.resource_entity_field = (
          selectedTemplate.sections?.[0]?.fields || []
        ).find((e) => e.key == field_key);
        this.resource_entity_field["template_id"] = template_id;
      }
      if (this.component?.job_entity) {
        let [template_id, field_key] = this.component.job_entity.split("#");
        let selectedTemplate = this.getAllCompanyTemplatesData.find(
          (e) => e._id == template_id
        );
        this.job_entity_field = (
          selectedTemplate.sections?.[0]?.fields || []
        ).find((e) => e.key == field_key);
        this.job_entity_field["template_id"] = template_id;
      }
      if (this.component?.scheduler_entity_sub_groups) {
        (this.component.scheduler_entity_sub_groups || []).map((e) => {
          let [template_id, field_key] = e.split("#");
          let selectedTemplate = this.getAllCompanyTemplatesData.find(
            (el) => el?._id == template_id
          );
          let field = (selectedTemplate.sections?.[0]?.fields || []).find(
            (e) => e.key == field_key
          );
          field["template_id"] = template_id;

          this.$set(this.scheduler_entity_sub_entity_field, e, field);
        });
      }
    },
    openSettings() { },
    deleteComponent() {
      this.$confirm("Are you sure want to delete the scheduler?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$emit("deleteComponent", this.index);
      });
    },
    openConfigDialog() {
      this.updateComponentData = {};
      this.updateComponentData = { ...this.componentData };
      this.openDialogVisible = true;
    },
    async updateComponent() {
      this.updateComponentData.component_name =
        this.updateComponentData.component_name.trim();
      if (!this.updateComponentData.component_name) {
        this.$message.error("Please enter table name");
        return;
      }
      let ex = this.customDashboardData.allTableComponents.filter(
        (e, i) =>
          i !== this.index &&
          e.component_name.toLowerCase() ==
          this.updateComponentData.component_name.toLowerCase()
      );
      if (ex && ex.length) {
        this.$notify.error({
          title: "Error",
          message: "Component name already exists",
        });
        return;
      }
      this.componentData = { ...this.updateComponentData };
      this.$emit("tableUpdate", {
        index: this.index,
        data: this.componentData,
        component: "SCHEDULING",
      });
      this.openDialogVisible = false;
    },
    async checkEmployeeAvailability(id, day) {
      if (this.component?.settings?.offdays) {
        let { allowed } = await this.verifyOffdays(id, day);
        return allowed;
      }
      return true;
    },
    checkIsPreviousDate(day) {
      let today = moment(new Date()).format("YYYY-MM-DD");
      let date = moment(new Date(day.year, day.month - 1, day.day)).format(
        "YYYY-MM-DD"
      );
      if (date < today) {
        return true;
      }
      return false;
    },
    async openDataModal(row, day, tasks = []) {
      let today = moment(new Date()).format("YYYY-MM-DD");
      let date = moment(new Date(day.year, day.month - 1, day.day)).format(
        "YYYY-MM-DD"
      );
      if (date < today) {
        this.$message({
          message: "Warning, Can't add task for previous dates",
          type: "warning",
        });
        return;
      }
      if (tasks.length && this.viewBy !== this.component.job_entit) {
        const tasksHours = this.calculateTotalHours(
          tasks
            .filter((e) => e[this.component.job_entity])
            .flatMap((e) => e[this.component.job_entity]),
          this.job_entity_field.entity_id,
          this.component.job_entity_time_field
        );
        const [t, k] = this.component.resource_entity_time_field.split("#");

        const employeeHours = row?.entityData?.[t]?.[k] || 0;

        if (employeeHours <= tasksHours) {
          if (
            !this.component?.settings ||
            !this.component?.settings?.allow_extra_time_job
          ) {
            this.$message({
              message: "Warning, Employee have enough tasks for this day",
              type: "warning",
            });
            return;
          } else if (this.component?.settings?.allow_extra_time_job) {
            this.$confirm(
              "Are you sure to add extra tasks for this employee",
              "Warning",
              {
                confirmButtonText: "OK",
                cancelButtonText: "Cancel",
                type: "warning",
              }
            ).then(() => {
              this.createNewSlot(row, day.day);
            });
            return;
          }
        }
      }
      if (this.component?.settings?.offdays) {
        let { allowed, messages } = await this.verifyOffdays(row._id, day.day);
        if (!allowed) {
          this.$message({
            message: messages,
            type: "warning",
          });
          return;
        }
      }
      this.createNewSlot(row, day.day);
    },
    async verifyOffdays(row, day) {
      let result = {
        allowed: true,
        messages: "",
      };
      await Promise.all(
        (this.component?.settings?.offdays || []).map(async (offday) => {
          let error = await this.verifyOffday(offday, row, day);
          if (error) {
            result.allowed = false;
            result.messages = offday.match_with
              ? "Employee is on leave!"
              : "Today is Holiday";
          }
          return error;
        })
      );
      return result;
    },
    async verifyOffday(offday, row, day) {
      let date = moment(
        new Date(
          this.currentWeek.getFullYear(),
          this.currentWeek.getMonth(),
          day
        )
      ).toISOString();
      let filters = [
        {
          field: offday.start_date,
          operator: offday.end_date ? "<=" : "=",
          value: date,
          value_type: "",
          value_source: "",
          data_type: "DATE",
          query_type: "AND",
          value_field: "",
          validations: {
            data_type: "",
          },
          relation_entity_id: "",
          relational_filter_query: "includes",
          isUserFilter: false,
          is_date_time: true,
          data_source: "VALUE",
          input_type: "DATE",
          is_formula: true,
        },
      ];
      if (offday?.end_date) {
        filters.push({
          field: offday.end_date,
          operator: ">=",
          value: date,
          value_type: "",
          value_source: "",
          data_type: "DATE",
          query_type: "AND",
          value_field: "",
          validations: {
            data_type: "",
          },
          relation_entity_id: "",
          relational_filter_query: "includes",
          isUserFilter: false,
          is_date_time: false,
          is_formula: true,
          data_source: "VALUE",
          input_type: "FORMULA",
        });
      }
      if (offday?.match_with) {
        filters.push({
          field: offday.match_with,
          operator: "in",
          value: [row],
          value_type: "",
          value_source: "",
          data_type: "OBJECT_ID",
          query_type: "AND",
          value_field: "",
          validations: {
            data_type: "",
          },
          isUserFilter: false,
          is_date_time: false,
          data_source: "ENTITY_DATA_LIST",
          input_type: "ENTITY",
        });
      }
      let checkData = await postAPICall("POST", "/entities-data/check", {
        entityId: offday.entity_id,
        filters: filters,
      });
      if (!checkData?.data) {
        return false;
      }
      return true;
    },
    createNewSlot(row, day) {
      this.entityData = null;
      this.isEdit = true;
      this.entityDataExists = false;
      this.entityDataId = null;
      this.schedulingTemplateForm = {};
      this.prepareSchedulingEntityTemplate(row, day);
      this.addingNewData = true;
    },
    getView(field, filters = null) {
      let { name, _id } = this.allEntities[field.entity_id];
      return {
        name: `View by ${name}`,
        entity_id: _id,
        key: field.template_id + "#" + field.key,
        filters,
      };
    },
    prepareSchedulingEntityTemplate(row, day) {
      this.currentDate = moment(
        new Date(
          this.currentWeek.getFullYear(),
          this.currentWeek.getMonth(),
          day
        )
      ).format(this.getDefaultDateFormat);
      if (
        this.component?.scheduler_entity &&
        this.allEntities?.[this.component.scheduler_entity]?.templates?.[0]
          ?.templateInfo
      ) {
        let schedulingTemplate =
          this.allEntities?.[this.component.scheduler_entity].templates[0]
            .templateInfo;
        let fields = schedulingTemplate?.sections?.[0]?.fields || [];
        fields.forEach((fd) => {
          if (
            ["DATE", "DATE_TIME"].includes(fd?.inputType) &&
            this.component.scheduler_entity_date_field ==
            schedulingTemplate._id + "#" + fd.key
          ) {
            this.schedulingTemplateForm[fd.key] = new Date(
              this.currentWeek.getFullYear(),
              this.currentWeek.getMonth(),
              day
            );
          } else if (fd.input_type == "ENTITY") {
            if (this.viewBy == schedulingTemplate._id + "#" + fd.key) {
              this.schedulingTemplateForm[fd.key] = row._id;
              this.schedulingTemplateForm[fd.key + "/name"] = this.getLabel(
                this.allEntities[
                this.selectedView(this.viewBy)?.entity_id || ""
                ],
                row,
                1
              );
            }
          }
        });
      }
    },
    getLabelFromFields(primaryFields, item) {
      let labels = [];
      primaryFields.forEach((field) => {
        let [template_id, key] = field.split("#");
        let label =
          item.entityData && template_id && item.entityData[template_id]
            ? item.entityData[template_id][key + "/name"]
              ? item.entityData[template_id][key + "/name"]
              : item.entityData[template_id][key]
                ? item.entityData[template_id][key]
                : ""
            : "";
        if (label) {
          labels.push(label);
        }
      });
      return labels.join(" - ");
    },
    getLabel(selectedEntity, item, i) {
      let name =
        item &&
          item.entity_prime_data &&
          Object.keys(item.entity_prime_data) &&
          item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          if (
            item.entity_prime_data &&
            item.entity_prime_data[key] &&
            item.entity_prime_data[key + "/name"]
          ) {
            name = item.entity_prime_data[key + "/name"]
              ? item.entity_prime_data[key + "/name"]
              : item.entity_prime_data[key];
          } else {
            name =
              item.entityData &&
                primaryField.template_id &&
                item.entityData[primaryField.template_id]
                ? item.entityData[primaryField.template_id][key + "/name"]
                  ? item.entityData[primaryField.template_id][key + "/name"]
                  : item.entityData[primaryField.template_id][key]
                    ? item.entityData[primaryField.template_id][key]
                    : "data" + i
                : "data" + i;
          }
        }
      }
      return name;
    },
    async getNeccessaryDetails() {
      this.loadingText = "Fetching data...";
      this.loading = true;
      this.setEntityIds();
      let promises = [];
      promises.push(
        this.fetchEntityDetails(
          this.component.scheduler_entity,
          true,
          false,
          false,
          false,
          false,
          true
        )
      );
      if (this.resource_entity_field?.entity_id) {
        promises.push(
          this.fetchEntityData(
            this.resource_entity_field.entity_id,
            this.resource_entity_field.filters || [],
            this.resource_entity_field.key
          )
        );
        if (!this.component.applyRelationship) {
          this.viewBy =
            this.resource_entity_field.template_id +
            "#" +
            this.resource_entity_field.key;
        }
      }
      if (this.job_entity_field?.entity_id) {
        promises.push(
          this.fetchEntityData(
            this.job_entity_field.entity_id,
            this.job_entity_field.filters || [],
            this.job_entity_field.key
          )
        );
        if (this.component.applyRelationship) {
          this.viewBy =
            this.job_entity_field.template_id + "#" + this.job_entity_field.key;
        }
      }
      if (this.component?.scheduler_entity) {
        promises.push(this.fetchEntityData(this.component.scheduler_entity));
      }
      if (
        this.scheduler_entity_sub_entity_field &&
        this.component?.scheduler_entity_sub_groups
      ) {
        (this.component.scheduler_entity_sub_groups || []).map((e) => {
          let field = this.scheduler_entity_sub_entity_field[e];
          let dividingFilters = (
            this.component?.scheduler_entity_sub_groups_filters || []
          ).filter((fl) => fl.split("@")[0] == e);
          if (dividingFilters.length) {
            dividingFilters.map((fl) => {
              promises.push(
                this.fetchEntityData(
                  field.entity_id,
                  [],
                  field.key,
                  fl.split("@")[1],
                  true
                )
              );
              promises.push(this.filtersData(fl.split("@")[1]));
            });
          } else {
            promises.push(
              this.fetchEntityData(
                field.entity_id,
                field.filters || [],
                field.key
              )
            );
          }
        });
      }
      promises.push(this.fetchContacts());
      promises.push(this.getCompanyUsersList());
      promises.push(
        this.$store.dispatch("contactTypes/fetchAllContactTypes", {
          get_all: true,
          type: "CONTACT_TYPE",
        })
      );
      let [allFields] = await Promise.all(promises);
      this.colorRulesFields = allFields.map((fd) => {
        fd.key = fd.template_key;
        return fd;
      });
      this.allViews = this.getAllViews;
      this.setColours();
      this.loading = false;
      await this.prepareScheduleEntityData();
    },
    async filtersData(filterId) {
      if (!this.allFiltersData[filterId]) {
        this.allFiltersData[filterId] = await fetchFilterById(filterId);
      }
    },
    setColours() {
      if (
        this.job_entity_field?.entity_id &&
        this.allEntitiesData[this.job_entity_field?.entity_id]
      ) {
        this.allEntitiesData[this.job_entity_field.entity_id].forEach((job) => {
          this.jobColors[job._id] = this.generateRandomLightColor();
        });
      }
    },
    async fetchEntityData(
      entity_id,
      filters = [],
      key = "",
      filter_id = "",
      dividingFilteredData = false
    ) {
      let entity_data_ids = [];
      if (
        this.component.applyRelationship &&
        (this.component?.scheduler_entity == entity_id ||
          this.resource_entity_field?.entity_id == entity_id) &&
        !this.checkApproverUserAccess
      ) {
        if (this.component?.scheduler_entity == entity_id) {
          filters = this.applyRelationalFilter(filters);
        } else {
          let { currentActiveWorkspace } =
            this.getCurrentApplicationUserDetails();
          if (currentActiveWorkspace?.account_data_id) {
            entity_data_ids = [currentActiveWorkspace.account_data_id];
          }
        }
      } else {
        filters = filters.filter(
          (e) => e.data_source !== "self_field" && e.data_source !== "TEMPLATE"
        );
      }
      let params = {
        entity_id: entity_id,
        limit: 500,
        page: 1,
        filters: this.mapDateFilters(
          this.component?.scheduler_entity == entity_id
            ? this.addGlobalFilters(
              filters || [],
              entity_id,
              this.checkGlobalFilterOnSingleStat(
                this.component?.scheduler_entity
              )
            )
            : filters
        ),
        filter_id: filter_id,
        entity_data_ids,
      };
      if (
        entity_id == this.component.scheduler_entity &&
        !this.component.applyRelationship
      ) {
        params["include_scheduling_data"] = true;
      }
      let entityDataResponse = await postAPICall(
        "POST",
        "/entities-data/entity/data",
        params
      );
      let entityData = entityDataResponse.data.map((data, i) => {
        data.label = this.getLabel(entityDataResponse.selectedEntity, data, i);
        return data;
      });
      this.allEntities[entity_id] = entityDataResponse.selectedEntity;
      if (dividingFilteredData) {
        this.allDividedEntitiesData[filter_id] = entityData;
      } else {
        this.allEntitiesData[entity_id] = entityData;
        let dependencyFilter = filters.find(
          (e) => e.data_source == "self_field" || e.data_source == "TEMPLATE"
        );
        if (key && !dependencyFilter) {
          this.entityFieldsPreferredData[key] = {
            ...entityDataResponse,
            ...{
              update: true,
            },
          };
        }
      }
    },
    async changeWeek(reset = false, add = false) {
      if (reset) {
        this.currentWeek = new Date();
      } else {
        this.currentWeek = new Date(
          this.currentWeek.getFullYear(),
          this.currentWeek.getMonth(),
          add ? this.currentWeek.getDate() + 7 : this.currentWeek.getDate() - 7
        );
      }
      if (this.currentYear != this.currentWeek.getFullYear()) {
        this.currentYear = this.currentWeek.getFullYear();
        this.$emit("newEntityData", {
          date: this.currentWeek,
          view: this.currentView,
        });
      }
      await this.prepareScheduleEntityData();
    },
    changeView(view) {
      this.currentView = view;
    },
  },
  watch: {
    component: {
      immediate: true,
      handler() {
        this.getNeccessaryDetails();
      },
    },
    getGlobalFiltervalue: {
      handler() {
        console.log(
          "this.component?.scheduler_entity",
          this.component?.scheduler_entity
        );
        if (
          this.checkGlobalFilterOnSingleStat(this.component?.scheduler_entity)
            ?.apply_on
        ) {
          this.getNeccessaryDetails();
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-dashboard-scheduler {
  border: 1px solid #eaeaea;
  border-radius: 5px;
  margin-bottom: 0px;
  margin-top: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff;
}

.left-card {
  flex: 1;
  width: 100%;
}

.right-card {
  flex: 4;
  width: 100%;
  justify-content: end;
  align-items: center;
}
</style>

<style scoped lang="scss">
th {
  white-space: nowrap;
  /* Prevent text wrapping */
  overflow: hidden;
  /* Ensure content stays within bounds */
  text-overflow: ellipsis;
  /* Add "..." for overflowed text */
  padding: 10px;
  /* Add some spacing */
  min-width: 120px;
  /* Set a minimum width */
  max-width: 200px;
  /* Set a maximum width */
}

th .table-header {
  display: inline-block;
  text-align: center;
  font-size: 14px;
  /* Adjust as needed */
  font-weight: bold;
  /* Ensure the header stands out */
}

th div {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
}

th i {
  font-size: 16px;
  /* Adjust icon size */
}

th span {
  display: inline-block;
  text-align: center;
}

.week-navigation el-button-group {
  display: flex;
  gap: 8px;
  /* Adjust spacing between buttons */
}

.week-navigation .el-button {
  margin: 0 4px;
  /* Additional padding around buttons */
}

.week-navigation {
  justify-content: flex-end;
  /* Ensures alignment to the right */
  margin-bottom: 16px;
  /* Adds some space below the button group */
}

.weekday-container {}

.weekday-single {
  width: 100% !important;
  height: 14px;
  border-radius: 4px;
  font-size: 9px;
  color: white;
  margin-top: 2px;
}

.event-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  min-height: 6px;
}

.single-event {
  height: 6px;
  width: 6px;
  border-radius: 50%;
}

.calendar {
  border-top: 1px solid #ccc;
  /* padding: 10px; */
  width: 100%;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.child-a {
  display: flex;
  align-items: center;
  gap: 10px;
}

.today-icon-container {
  display: inline-block;
  position: relative;
}

.prev-next-btn {
  font-size: 20px;
  color: #007bff;
  margin: 0 10px;
}

.current-date {
  font-size: 16px;
  color: #333;
}

.view-select {
  width: 100px;
  font-size: 14px;
  color: #333;
  margin-right: 20px;
}

.el-tooltip__popper {
  font-size: 12px;
  /* Tooltip font size */
}

/* Styles for the calendar table */
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: center;
  border: 1px solid #ccc;
}

td {
  height: 75px;
  position: relative;
  vertical-align: center;
}

td .cell-content {
  padding: 10px;
}

.current-day {
  background-color: lightblue;
}

.cell-content {
  position: relative;
  padding: 5px;
}

.event {
  background-color: #f0f0f0;
  border-radius: 3px;
  padding: 2px 5px;
  margin-top: 2px;
}

.event:first-child {
  margin-top: 5px;
}

.table-header {
  font-weight: bold !important;
}

.table-cell {
  position: relative;
  padding: 5;
}

.hidden-button {
  display: none;
}

.table-cell:hover .hidden-button {
  display: block;
}

.job-card {
  //background-color: lightblue !important;
  width: 100% !important;
  border-radius: 5px;
  cursor: pointer;
}

.remove-button {
  display: none;
}

.job-card:hover .remove-button {
  display: block;
}
</style>
